import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Empty, Toast, Dialog, Loading, Popup, Collapse, CollapseItem } from 'vant'
import { setTimeout } from 'core-js'
import { ImagePreview } from 'vant'

// 全局注册
Vue.use(ImagePreview)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Popup)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(Toast)
export default {
  components: {},
  data () {
    return {
      name: '',
      begin: '',
      end: '',
      illnessNote: '',
      address: '',
      applyHospital: '',
      applyDepartment: '',
      doctorName: '',
      medicareCard: '',
      standby1: '',
      medicalRecordImage: [],
      otherMaterialImage: [],
      theid: '',
      appointdate: '',
      servetype: '',
      serveaddress: '',


    }
  },
  created () {
    this.name = this.$route.query.name
    if (this.$route.query.theid) {
      this.theid = this.$route.query.theid
    }
    Toast({
      type: "loading",
      message: '加载中...',
      forbidClick: true,
      duration: 0
    })
    this.getList()
  },

  methods: {
    ...mapActions('equityCenter', ['getUsageRecordDetail']),
    back () {
      if (this.theid != '') {
        this.$router.push({ name: 'equityDatail', query: { id: this.theid, isback: 1 } })

      } else {
        this.$router.go(-1)
      }


    },
    getImg (images, index) {
      ImagePreview({
        images: this.medicalRecordImage,
        showIndex: true,
        loop: false,
        startPosition: index
      })
    },

    getImg1 (images, index) {
      ImagePreview({
        images: this.otherMaterialImage,
        showIndex: true,
        loop: false,
        startPosition: index
      })
    },


    getList () {
      var data = {
        equityUsageRecordId: this.$route.query.fId
      }
      this.getUsageRecordDetail(data).then((res) => {
        if (res.code == 200) {

          setInterval(() => {
            Toast.clear()

          }, 1000)
          this.begin = res.data.applyHospitalStartDate
          this.end = res.data.applyHospitalEndDate
          this.illnessNote = res.data.illnessNote
          if (res.data.address == null) {
            this.address = res.data.address
          } else {
            this.address = res.data.address.split('/').join('')
          }
          this.applyHospital = res.data.applyHospital
          this.applyDepartment = res.data.applyDepartment
          this.doctorName = res.data.doctorName
          this.medicareCard = res.data.medicareCard
          this.standby1 = res.data.standby1
          this.otherMaterialImage = res.data.otherMaterialImage.substr(0, res.data.otherMaterialImage.length).split(',')
          this.medicalRecordImage = res.data.medicalRecordImage.substr(0, res.data.medicalRecordImage.length).split(',')
          this.appointdate = res.data.appointdate
          this.serveaddress = res.data.serveaddress
          if (res.data.servetype == 'self') {
            this.servetype = '自行到店'
          } else if (res.data.servetype == 'self') {
            this.servetype = '上门服务'
          }
          console.log(res.data.servetype, 99999)



        } else {

        }
      })
    }

  },
}
